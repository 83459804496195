<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="700">
    <v-card>
      <v-card-title class="headline">{{ $t('login.titlelogin') }}</v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="ilogin"
            :label="$t('login.login')"
            required
            :rules="[(v) => !!v || $t('all.fieldnoempty')]"
          ></v-text-field>
          <v-text-field
            v-model="ipass"
            :label="$t('login.pass')"
            required
            type="password"
            :rules="[(v) => !!v || $t('all.fieldnoempty')]"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <a href="#" v-on:click="link('reg')">{{ $t('login.registration') }}</a>
        <br />&nbsp;&nbsp;&nbsp;
        <!--a href="#" v-on:click="link('fpass')">{{ $t('login.resetpass') }}</a-->
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click.native="login">{{
          $t('login.start')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      ilogin: '',
      ipass: '',
      check: false,
      ResultLogin: '',
    }
  },
  computed: {
    redirectToAfterLogin: function () {
      //            console.log('111111');
      console.log('redirect', this.$route.query.redirect)
      if (this.$route.query.redirect) {
        return this.$route.query.redirect
      } else {
        return 'Smena'
      }
    },
  },

  components: {},
  methods: {
    link: function (l) {
      this.$router.push(l)
    },
    login: function () {
      var t = this
      this.check = true
      if (this.ilogin == '' || this.ipass == '') {
        return false
      }
      t.$store.commit('clear')
      this.$http
        .post(this.$store.state.apiUrl + 'login/auth', {
          login: this.ilogin,
          pass: this.ipass,
        })
        .then(
          (response) => {
            if (response.body.err > 0) {
              t.$store.dispatch('setMessage', {
                type: response.body.err > 0 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
            } else {
              t.ResultLogin = response.body
              this.$store.commit('mLogin', t.ResultLogin)
              this.$router.push(this.redirectToAfterLogin)
            }
          },
          (err) => {
            console.log(err)
            t.ResultLogin = {
              err: 1,
              msg: t.$t('all.errorrequest'),
              login: t.ilogin,
            }
          }
        )
    },
  },
}
</script>

<style></style>
